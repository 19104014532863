import { $fetch, $post, $put } from '@/services/axios';

export function fetch(params) {
  return $fetch(`customer/configs`, params);
}

export const CONFIG_CSV_URL = `customer/configs/csv`;

export function validate(model) {
  return $post(`customer/config/validate`, model);
}

export function create(model) {
  return $put(`customer/config`, model);
}

export function update(model) {
  return $post(`customer/config/${model.id}`, model);
}
