<template>
  <!-- prettier-ignore -->
  <n-modal v-bind="$props" :width="500">
    <Form ref="modalForm" :model="model" :label-width="labelWidth" :rules="rules" class="n-modal-container" @submit.native.prevent>
      <n-row-col1>
        <ns-company v-model="model.companyId" :error="error.companyId" :disabled="isEdit || disabled" />
      </n-row-col1>
      <n-row-col1>
        <n-field-id v-model="model.customerId" field-name="customerId" label="customerId" :error="error.customerId" :disabled="isEdit || disabled" />
      </n-row-col1>
      <n-row-col1>
        <n-field-id v-model="model.accountId" field-name="accountId" label="accountId" :error="error.accountId" :disabled="isEdit || disabled" />
      </n-row-col1>
      <n-row-col1>
        <n-field-enum v-model="model.status" field-name="status" enum-name="CustomerConfigStatus" label="configStatus" :error="error.status" :disabled="disabled" />
      </n-row-col1>
      <n-row-col1>
        <n-field-enum v-model="model.configType" field-name="configType" enum-name="CustomerConfigType" label="configType" :error="error.configType" :disabled="isEdit || disabled" />
      </n-row-col1>
      <n-row-col1>
        <n-field-text v-model="model.configValue" field-name="configValue" label="configValue" :error="error.configValue" :disabled="disabled" />
      </n-row-col1>
    </Form>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';
import { shouldBeJson, shouldNotEmpty } from '@/helpers/validate';
import { create, update, validate } from '@/api/customer/customer-config';

export default NModal.extend({
  name: 'ModalConfig',

  components: { NModal },

  data() {
    return {
      rules: {
        configValue: [shouldNotEmpty, shouldBeJson],
      },
    };
  },

  methods: {
    doValidate(model) {
      return validate(model);
    },

    doSubmit(model) {
      return this.isCreate ? create(model) : update(model);
    },
  },
});
</script>
